.contacts-block {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.contact-wrapper {
  width: 40%;
  background: lightgrey;
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem auto;
}

.contact-us-form {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .contact-wrapper {
    width: 80%;
  }
}