@import '../../_colors.scss';

.search-form {
  display: flex;
  justify-content: center;

  $input_font_size: 13px;

  .search-input {
    border-radius: 30px;
    border: 1px solid $main_color;
    padding: 5px;
    font-size: $input_font_size;
    color: $main_color;
    text-transform: uppercase;

    &:hover, &:focus-visible {
      outline: none;
    }
    
    &:focus-visible {
      background: -webkit-linear-gradient(#4158D0, #C850C0, #FFCC70);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $main_color;
      font-size: $input_font_size;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: $main_color;
      font-size: $input_font_size;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: $main_color;
      font-size: $input_font_size;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: $main_color;
      font-size: $input_font_size;
    }
  }
}

@media screen and (max-width: 1000px) {
  .search-input {
    padding: 5px !important;
    font-size: 11px;
    color: $main_color;
    margin: 0;  
  }
}

@media screen and (min-width: 800px) {
  .search-input {
    width: 20vw;
  }
}