.loader-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  background: rgba(255,255,255, 0);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader-star {
  position: absolute;
  top: calc(50% - 12px);
}

/*LOADER-2*/

.loader-2 .loader-star {
  position: static;
  width: 60px;
  height: 60px;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-animation: loader-2-star 1s ease alternate infinite;
  animation: loader-2-star 1s ease alternate infinite;
}

.loader-2 .loader-circles {
  width: 8px;
  height: 8px;
  background: #e5062f;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 4px);
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation: loader-2-circles 1s ease-in-out alternate infinite;
  animation: loader-2-circles 1s ease-in-out alternate infinite;
}

/* ----------------     KEYFRAMES    ----------------- */

@-webkit-keyframes loader-2-circles {
  0% {
    -webkit-box-shadow: 0 0 0 #e5062f;
    box-shadow: 0 0 0 #e5062f;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-box-shadow: 24px -22px #e5062f, 30px -15px 0 -3px #e5062f, 31px 0px #e5062f, 29px 9px 0 -3px #e5062f, 24px 23px #e5062f, 17px 30px 0 -3px #e5062f, 0px 33px #e5062f, -10px 28px 0 -3px #e5062f, -24px 22px #e5062f, -29px 14px 0 -3px #e5062f, -31px -3px #e5062f, -30px -11px 0 -3px #e5062f, -20px -25px #e5062f, -12px -30px 0 -3px #e5062f, 5px -29px #e5062f, 13px -25px 0 -3px #e5062f;
    box-shadow: 24px -22px #e5062f, 30px -15px 0 -3px #e5062f, 31px 0px #e5062f, 29px 9px 0 -3px #e5062f, 24px 23px #e5062f, 17px 30px 0 -3px #e5062f, 0px 33px #e5062f, -10px 28px 0 -3px #e5062f, -24px 22px #e5062f, -29px 14px 0 -3px #e5062f, -31px -3px #e5062f, -30px -11px 0 -3px #e5062f, -20px -25px #e5062f, -12px -30px 0 -3px #e5062f, 5px -29px #e5062f, 13px -25px 0 -3px #e5062f;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-box-shadow: 25px -22px #e5062f, 15px -22px 0 -3px black, 31px 2px #e5062f, 21px 2px 0 -3px black, 23px 25px #e5062f, 13px 25px 0 -3px black, 0px 33px #e5062f, -10px 33px 0 -3px black, -26px 24px #e5062f, -19px 17px 0 -3px black, -32px 0px #e5062f, -23px 0px 0 -3px black, -25px -23px #e5062f, -16px -23px 0 -3px black, 0px -31px #e5062f, -2px -23px 0 -3px black;
    box-shadow: 25px -22px #e5062f, 15px -22px 0 -3px black, 31px 2px #e5062f, 21px 2px 0 -3px black, 23px 25px #e5062f, 13px 25px 0 -3px black, 0px 33px #e5062f, -10px 33px 0 -3px black, -26px 24px #e5062f, -19px 17px 0 -3px black, -32px 0px #e5062f, -23px 0px 0 -3px black, -25px -23px #e5062f, -16px -23px 0 -3px black, 0px -31px #e5062f, -2px -23px 0 -3px black;
  }
}

@keyframes loader-2-circles {
  0% {
    -webkit-box-shadow: 0 0 0 #e5062f;
    box-shadow: 0 0 0 #e5062f;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-box-shadow: 24px -22px #e5062f, 30px -15px 0 -3px #e5062f, 31px 0px #e5062f, 29px 9px 0 -3px #e5062f, 24px 23px #e5062f, 17px 30px 0 -3px #e5062f, 0px 33px #e5062f, -10px 28px 0 -3px #e5062f, -24px 22px #e5062f, -29px 14px 0 -3px #e5062f, -31px -3px #e11a2b, -30px -11px 0 -3px #e5062f, -20px -25px #e5062f, -12px -30px 0 -3px #e5062f, 5px -29px #e5062f, 13px -25px 0 -3px #e5062f;
    box-shadow: 24px -22px #e5062f, 30px -15px 0 -3px #e5062f, 31px 0px #e5062f, 29px 9px 0 -3px #e5062f, 24px 23px #e5062f, 17px 30px 0 -3px #e5062f, 0px 33px #e5062f, -10px 28px 0 -3px #e5062f, -24px 22px #e5062f, -29px 14px 0 -3px #e5062f, -31px -3px #e5062f, -30px -11px 0 -3px #e5062f, -20px -25px #e5062f, -12px -30px 0 -3px #e5062f, 5px -29px #e5062f, 13px -25px 0 -3px #e5062f;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-box-shadow: 25px -22px #e5062f, 15px -22px 0 -3px black, 31px 2px #e5062f, 21px 2px 0 -3px black, 23px 25px #e5062f, 13px 25px 0 -3px black, 0px 33px #e5062f, -10px 33px 0 -3px black, -26px 24px #e5062f, -19px 17px 0 -3px black, -32px 0px #e5062f, -23px 0px 0 -3px black, -25px -23px #e5062f, -16px -23px 0 -3px black, 0px -31px #e5062f, -2px -23px 0 -3px black;
    box-shadow: 25px -22px #e5062f, 15px -22px 0 -3px black, 31px 2px #e5062f, 21px 2px 0 -3px black, 23px 25px #e5062f, 13px 25px 0 -3px black, 0px 33px #e5062f, -10px 33px 0 -3px black, -26px 24px #e5062f, -19px 17px 0 -3px black, -32px 0px #e5062f, -23px 0px 0 -3px black, -25px -23px #e5062f, -16px -23px 0 -3px black, 0px -31px #e5062f, -2px -23px 0 -3px black;
  }
}

@-webkit-keyframes loader-2-star {
  0% {
    -webkit-transform: scale(0) rotate(0deg);
    transform: scale(0) rotate(0deg);
  }
  100% {
    -webkit-transform: scale(0.7) rotate(360deg);
    transform: scale(0.7) rotate(360deg);
  }
}

@keyframes loader-2-star {
  0% {
    -webkit-transform: scale(0) rotate(0deg);
    transform: scale(0) rotate(0deg);
  }
  100% {
    -webkit-transform: scale(0.7) rotate(360deg);
    transform: scale(0.7) rotate(360deg);
  }
}
