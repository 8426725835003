@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s ease;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  
  &.dark {
    background-color: #3d3d3d;
  }

  &.color {
    background: linear-gradient(0deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 35%, rgba(252, 176, 69, 1) 100%);
  }

}

.hidden {
  display: none !important;
}

.visible {
  display: block;
}