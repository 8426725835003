@import '../../_colors.scss';

.channel-description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.navigation {
  position: relative;
  height: 56px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    background-repeat: no-repeat;
    background-position: 50%;
    width: 40px;
    height: 40px;
    margin: 10px;
    background-size: contain;
  }
}

@media screen and (max-width: 768px) {
  .navigation {
    justify-content: initial;
  }
}