.menu-icon {
    width: 17px;
    height: 17px;
    padding: 10px;
    cursor: pointer;
    animation-name: menu-icon;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    z-index: 11;

    .cls-el {
      fill: #231f20;
    }

    .cls-el-1 {
        animation-name: menu-icon-cls-el-1;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        &:hover {
            fill: #ea0f16;
        }
    }
    .cls-el-2 {
        animation-name: menu-icon-cls-el-1;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        &:hover {
            fill: #9deb1f;
        }
    }
    .cls-el-3 {
        animation-name: menu-icon-cls-el-2;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        &:hover {
            fill: #0f96ea;
        }
    }
    .cls-el-4 {
        animation-name: menu-icon-cls-el-2;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        &:hover {
            fill: #eded12;
        }
    }
}

.sidebar {
    height: 100vh;
    width: 200px;
    position: fixed;
    z-index: 10;
    top: 0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);    
    overflow-x: hidden;
    padding-top: 60px;
    left: -200px;
    opacity: 0;

    &.open {
        animation-name: sidebar-open;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        opacity: 1;
    }
    
    &.close {
        animation-name: sidebar-close;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    .sidebar__item {
        padding: 6px 8px 6px 16px;
        text-decoration: none;
        font-size: 14px;
        color: #818181;
        display: block;
        transition: .3s;
        cursor: pointer;

        &:hover {
            color: #D60023;
        }

        .sidebar__text {
            margin-left: 10px;

            a {
                text-decoration: none;
            }
        }
    }
}

@keyframes sidebar-open {
    from {
        left: -200px;
    }
    to {
        left: 0;
    }
}

@keyframes sidebar-close {
    from {
        left: 0;
    }
    to {
        left: -200px;
    }
}

@keyframes menu-icon {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(360deg);
    }
    20% {
        transform: rotate(0deg);
    }
}

@keyframes menu-icon-cls-el-1 {
    0% {
        fill: #ea0f16;
    }
    10% {
        fill: #9deb1f;
    }
    20% {
        fill: #000;
    }
}

@keyframes menu-icon-cls-el-2 {
    0% {
        fill: #2b03cb;
    }
    10% {
        fill: #ebb11f;
    }
    20% {
        fill: #000;
    }
}

